<template>
  <div>
    <b-card no-body class="mb-0">
      <!-- Pagination -->
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="8"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ localize["table_show"] }}</label>
            <v-select
              v-model="perPage"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="4">
            <div class="d-flex justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
            </div>
          </b-col>
        </b-row>
      </div>
      <!-- Table Control -->
      <b-table
        ref="refUserListTable"
        :items="fetchDataTable"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        responsive
        show-empty
        hover
        primary-key="id"
        @row-clicked="handleRowClicked"
      >
        <!-- Header -->

        <template #head(totalBalance)="data">
          <div v-if="statsDebts" class="d-flex flex-column">
            <div class="d-flex justify-content-left">
              {{ data.label }}
            </div>
            <div class="d-flex justify-content-left gap-1">
              <div>
                {{ formatCurrency(statsDebts.totalBalance) }}
                <span class="text-lowercase">đ</span>
              </div>
            </div>
          </div>
        </template>

        <template #head(totalBalancePaid)="data">
          <div v-if="statsDebts" class="d-flex flex-column">
            <div class="d-flex justify-content-left">
              {{ data.label }}
            </div>
            <div class="d-flex justify-content-left gap-1">
              <div>
                {{ formatCurrency(statsDebts.totalBalancePaid) }}
                <span class="text-lowercase">đ</span>
              </div>
            </div>
          </div>
        </template>

        <template #head(totalDeposited)="data">
          <div v-if="statsDebts" class="d-flex flex-column">
            <div class="d-flex justify-content-left">
              {{ data.label }}
            </div>
            <div class="d-flex justify-content-left gap-1">
              <div>
                {{ formatCurrency(statsDebts.totalDeposited) }}
                <span class="text-lowercase">đ</span>
              </div>
            </div>
          </div>
        </template>

        <template #head(balance)="data">
          <div v-if="statsDebts" class="d-flex flex-column">
            <div class="d-flex justify-content-left">
              {{ data.label }}
            </div>
            <div class="d-flex justify-content-left gap-1">
              <div>
                {{ formatCurrency(statsDebts.totalBalancePlus) }}
                <span class="text-lowercase">đ</span>
              </div>
            </div>
          </div>
        </template>

        <template #head(balances)="data">
          <div v-if="statsDebts" class="d-flex flex-column">
            <div class="d-flex justify-content-left">
              {{ data.label }}
            </div>
            <div class="d-flex justify-content-left gap-1">
              <div>
                {{ formatCurrency(statsDebts.totalBalanceMinus) }}
                <span class="text-lowercase">đ</span>
              </div>
            </div>
          </div>
        </template>

        <template #empty>
          <div v-if="state.loading" class="text-center my-2">
            <p>{{ localize["table_loading"] }}</p>
          </div>
          <div
            v-if="!state.loading && totalRows === 0"
            class="text-center my-2"
          >
            {{ localize["table_empty"] }}
          </div>
        </template>

        <!-- Custom Column -->

        <template #cell(userId)="{ item }">
          <div class="text-center text-wrap" style="min-width: 50px">
            {{ item.userId }}
          </div>
        </template>

        <template #cell(fullName)="{ item }">
          <div class="text-wrap" style="min-width: 80px">
            {{ item.fullName }}
          </div>
        </template>

        <template #cell(username)="{ item }">
          <div class="text-wrap" style="min-width: 80px">
            {{ item.username }}
          </div>
        </template>

        <template #cell(totalOrderWait)="{ item }">
          <div class="text-wrap" style="min-width: 60px">
            {{ formatCurrency(item.totalOrderWait) }}
          </div>
        </template>

        <template #cell(totalOrder)="{ item }">
          <div class="text-wrap" style="min-width: 60px">
            {{ formatCurrency(item.totalOrder) }}
          </div>
        </template>

        <template #cell(totalBalance)="{ item }">
          <div class="text-wrap text-left" style="min-width: 120px">
            {{ formatCurrency(item.totalBalance) }}
          </div>
        </template>

        <template #cell(totalDeposited)="{ item }">
          <div class="text-wrap text-left" style="min-width: 110px">
            {{ formatCurrency(item.totalDeposited) }}
          </div>
        </template>

        <template #cell(totalBalancePaid)="{ item }">
          <div class="text-wrap text-left" style="min-width: 120px">
            {{ formatCurrency(item.totalBalancePaid) }}
          </div>
        </template>

        <template #cell(balance)="{ item }">
          <div
            class="text-wrap text-left text-success"
            style="min-width: 110px"
          >
            {{ item.balance > 0 ? formatCurrency(item.balance) : "" }}
          </div>
        </template>

        <template #cell(balances)="{ item }">
          <div class="text-wrap text-left text-danger" style="min-width: 110px">
            {{ item.balance < 0 ? formatCurrency(item.balance) : "" }}
          </div>
        </template>
      </b-table>

      <!-- Pagination < Pre/Next > -->
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">
              Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries
            </span>
          </b-col>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalRows"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import { SortByEnum } from "@/@core/utils/enums/sort-by.enum";
import { UserTab } from "@/@core/utils/enums/tab.enum";
import { RoleInfoParse, UserStatusParse } from "@/@core/utils/enums/user.enum";
import useTable from "@/base/table/table";
import { localize } from "@/helpers/localize";
import { customTime, formatCurrency } from "@/helpers/untils";
import useToastHandler from "@/services/toastHandler";
import {
  BBadge,
  BButton,
  BCard,
  BCol,
  BFormInput,
  BPagination,
  BRow,
  BTable,
  VBModal,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { mapActions, mapState } from "vuex";
import useUsers from "./useUsers";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BPagination,
    vSelect,
    BButton,
    BBadge,
  },
  directives: {
    "b-modal": VBModal,
  },
  data() {
    return {
      statsDebts: null,
    };
  },
  computed: {
    ...mapState({
      state: (s) => {
        return s.apps.debt.debtList;
      },
    }),
  },
  methods: {
    ...mapActions({
      getUsers: "users/userList/getUsers",
      getUserDebts: "debt/debtList/getUserDebts",
    }),

    async fetchDataTable() {
      const data = await this.getUserDebts({
        search: this.searchQuery,
        limit: this.perPage,
        page: this.currentPage,
        order: this.sortBy ?? undefined,
        by: this.sortBy
          ? this.isSortDirDesc
            ? SortByEnum.DESC
            : SortByEnum.ASC
          : undefined,
      });
      this.totalRows = this.state.totalPage;
      if (data?.statsDebts) this.statsDebts = data.statsDebts;
      return data?.userDebts?.length ? data.userDebts : [];
    },
    handleRowClicked(item, index, event) {
      this.$router.push({
        name: "user-sub-menu",
        params: { userId: item.userId },
        query: { tab: UserTab.Info },
      });
    },
  },
  mounted() {
    this.refUsers = this.refUserListTable;
  },
  setup() {
    return {
      ...useTable(),
      ...useUsers(),
      ...useToastHandler(),
      localize,
      UserStatusParse,
      RoleInfoParse,
      customTime,
      formatCurrency,
    };
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
./useDebts ./useUsers
