import { formatCurrency } from "@/helpers/untils";
import { ref, watch } from "@vue/composition-api";
// Notification

export default function useUsers() {
  const statusFilter = ref(null);
  const roleFilter = ref(null);
  const refUsers = ref(null);
  const sortBy = ref(null);
  const isSortDirDesc = ref(null);

  const tableColumns = [
    {
      key: "userId",
      label: "Mã KH",
    },
    { key: "fullName", label: "Tên khách hàng" },
    { key: "username", label: "Tên người dùng" },

    {
      key: "totalOrder",
      label: "Số đơn hàng",
      thClass: "text-center",
      tdClass: "text-center",
      formatter: (value) => {
        return formatCurrency(value);
      },
    },
    {
      key: "totalOrderWait",
      label: "Số đơn chờ",
      thClass: "text-center",
      tdClass: "text-center",
      formatter: (value) => {
        return formatCurrency(value);
      },
    },
    {
      key: "totalBalance",
      label: "Tổng tiền hàng",
    },
    {
      key: "totalBalancePaid",
      label: "Đã thanh toán",
    },
    {
      key: "totalDeposited",
      label: "Tiền đã nạp",
    },
    {
      key: "balance",
      label: "Khách dư tiền",
    },
    {
      key: "balances",
      label: "Khách âm tiền",
    },
  ];

  watch([statusFilter, roleFilter], () => {
    refUsers.value.refresh();
  });

  return {
    tableColumns,
    statusFilter,
    roleFilter,
    sortBy,
    isSortDirDesc,
    refUsers,
  };
}
